import React, { FC } from "react";
import { TailwindSkeleton as Skeleton } from "component-tailwind-skeletons";
import { Grid } from "utils/material-ui-core";

export interface MultilineSkeletonProps {
  lines: number;
  className?: string;
  style?: React.CSSProperties;
}

export const MultilineSkeleton: FC<MultilineSkeletonProps> = ({
  lines,
  className,
  style
}) => {
  return (
    <Grid className={className} container direction="column" style={style}>
      {Array.from(Array(lines - 1), (_, index) => (
        <Grid item key={index}>
          <Skeleton variant="text" width="100%" />
        </Grid>
      ))}
      <Grid item>
        <Skeleton variant="text" width="60%" />
      </Grid>
    </Grid>
  );
};

export default MultilineSkeleton;
