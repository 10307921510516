import { Theme } from "utils/material-ui-core";
import { FONT_THIN, FONT_BARON_SANS } from "utils/constants";
import { createMuiTheme } from "@material-ui/core";

export const rhModern = {
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: FONT_BARON_SANS,
        fontWeight: FONT_THIN
      },
      h2: {
        fontFamily: FONT_BARON_SANS,
        fontWeight: FONT_THIN
      },
      h3: {
        fontFamily: FONT_BARON_SANS,
        fontWeight: FONT_THIN
      },
      h6: {
        fontFamily: FONT_BARON_SANS,
        fontWeight: FONT_THIN
      }
    }
  },
  palette: {
    secondary: {
      light: "#000000"
    }
  },
  brandColors: {
    priceRange: "#FFFFFF",
    tempHeaderColor: "#FFFFFF",
    tempBodyColor: "#FFFFFF"
  }
};

export const rhBabyAndChild = {
  overrides: {
    MuiTypography: {
      h1: {
        color: "#474747"
      },
      h2: {
        color: "#474747"
      },
      h3: {
        color: "#474747"
      },
      h6: {
        color: "#474747"
      }
    }
  },
  brandColors: {
    headerIconLight: "#666666"
  }
};

export default (defaultTheme: Theme, brand: string) => {
  switch (brand) {
    case "MO":
      return createMuiTheme(defaultTheme, rhModern);
    case "BC":
      return createMuiTheme(defaultTheme, rhBabyAndChild);
    default:
      return defaultTheme;
  }
};
