import React, { FC, useCallback } from "react";
import { TailwindSkeleton as Skeleton } from "component-tailwind-skeletons";
import {
  Grid,
  Card,
  CardContent,
  Theme,
  makeStyles,
  createStyles
} from "utils/material-ui-core";
import { ImageSkeleton } from "skeleton-image";
import { IMAGE_ASPECT_RATIO } from "utils/constants";
import useResponsiveGridSpacing from "hooks/useResponsiveGridSpacing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heroImage: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
        // TODO: Fix grid for mobile so PDP hero can be full width without this hack
        marginLeft: "-4px !important",
        marginRight: "-4px !important",
        width: "100vw",
        maxWidth: "100vw"
      }
    }
  })
);

interface BaseProductSkeletonProps {
  isRHRProduct?: boolean;
  layout?: string;
  animation?: "pulse" | "wave" | false;
}

export const BaseProductSkeleton: FC<BaseProductSkeletonProps> = ({
  isRHRProduct = false,
  layout = "square",
  animation
}) => {
  const gridSpacing = useResponsiveGridSpacing();
  const classes = useStyles();
  const getAspectRatio = useCallback(() => {
    if (layout === "Horizontal")
      return IMAGE_ASPECT_RATIO["horizontalProductTile"];
    else if (layout === "Vertical")
      return IMAGE_ASPECT_RATIO["verticalProductTile"];
    return IMAGE_ASPECT_RATIO["heroImage"];
  }, [layout]);

  return (
    <Card elevation={0} square>
      {!isRHRProduct ? (
        <Grid container spacing={gridSpacing} className={"md:py-14 md:px-0"}>
          <Grid item xs={12} md={6}>
            <ImageSkeleton
              aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
              animation={animation}
              dataTestid="base-product-image-skeleton"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardContent data-testid="base-product-card-skeleton">
              <Skeleton />
              <Skeleton width="60%" />
              <br />
              <Skeleton />
              <Skeleton />
              <br />
              <Skeleton width="30%" />
              <Skeleton width="60%" />
              <Skeleton width="60%" />
              <br />
              <Skeleton />
              <Skeleton />
              <br />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </CardContent>
          </Grid>
        </Grid>
      ) : (
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid
              item
              xs={12}
              md={layout.toLowerCase() === "square" ? 6 : 12}
              sm={layout.toLowerCase() === "square" ? 6 : 12}
              style={{ height: "400px" }}
            >
              <ImageSkeleton
                aspectRatio={getAspectRatio()}
                animation={animation}
                dataTestid="base-product-image-skeleton"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={layout.toLowerCase() === "square" ? 6 : 12}
              data-testid="base-product-card-skeleton"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Skeleton variant="text" width="100%" height={20} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={layout.toLowerCase() === "square" ? 12 : 4}
                    >
                      <Skeleton variant="text" width="100%" height={20} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={layout.toLowerCase() === "square" ? 12 : 4}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Skeleton variant="rect" width="100%" height={44} />
                        </Grid>
                        <Grid item xs={6}>
                          <Skeleton variant="rect" width="100%" height={44} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={layout.toLowerCase() === "square" ? 12 : 4}
                    >
                      <Skeleton variant="text" width="100%" height={20} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

export default BaseProductSkeleton;
